<template>
  <div>
  <Loader v-if="state.loading" />

  <b-row
  v-if="state && state.invoiceDetails && !state.loading"
  class="d-flex p-1 text flex-nowrap" >
    <b-col cols="9">
    <b-card

    id="print" >
      <div class="pb-3 pr-3 pl-3">
        <b-row class="d-flex justify-content-between">
          <div class="mb-3 d-flex align-items-center">
            <!-- <img 
    src="https://cdn.niso.dev/8c1fb068-5aaf-4f37-9a88-a4debe1509ca/734a3c12-728c-41b2-96b6-c181e129effb.png" 
    alt="logo" 
    style="height: 100px;width: 130px;"
    > -->
            <!-- <span class="logoText"> -->
              <img 
              style="border-radius: 8px;"
              width="140"
              height="90"
              :src="state.imageUrl"/>
            <!-- </span> -->
          </div>
          <h4>Invoice# <strong>{{state.invoiceDetails.invoiceNumber}}</strong></h4>
        </b-row>
        <b-row class="d-flex justify-content-between">
          <!-- <b-col cols="8"> -->
          <div>
            <b-card-text>Office 149, 450 South Brand Brooklyn</b-card-text>
            <b-card-text>San Diego County, CA 91905, USA</b-card-text>
            <b-card-text>+1 (123) 456 7891, +44 (876) 543 2198</b-card-text>
          </div>
          <div class="d-flex flex-column">
            <b-row v-if="$route.fullPath.includes('edit')" class="align-items-center">
              <label
                class="cardText"
                style="font-size: 16px; margin-right: 10px"
                for="issueDate"
                >Date Issued:</label
              >
              <span>
                <b-form-datepicker id="issueDate"></b-form-datepicker>
              </span>
            </b-row>
            <b-row v-else>
              <b-card-text class="cardText">Date Issued:</b-card-text>
              <strong>{{state.invoiceDetails.issueDate}}</strong>
            </b-row>
            <b-row v-if="$route.fullPath.includes('edit')" class="align-items-center mt-1">
              <label
                class="cardText"
                style="font-size: 16px; margin-right: 10px"
                for="dueDate"
                >Due Date:</label
              >
              <span>
                <b-form-datepicker id="dueDate"></b-form-datepicker>
              </span>
            </b-row>
            <b-row v-else>
              <b-card-text class="cardText">Due Date:</b-card-text>
              <b-form-datepicker v-if="$route.fullPath.includes('edit')"></b-form-datepicker>
              <strong v-else>13 Dec 2019</strong>
            </b-row>
          </div>

          <!-- </b-col> -->
          <!-- <b-col cols="4">
       
    </b-col> -->
        </b-row>
      </div>
      <div class="border" />
      <b-row class="d-flex justify-content-between pr-3 pl-3 pb-3">
        <div>
          <b-card-text class="text mb-2"
            ><strong>Invoice To: </strong></b-card-text
          >
          <customer-select-vue
            @selectionChanged="handleCustomerSelection"
            :selected="state.selected"
            v-if="$route.fullPath.includes('edit')"
          />
          <div>
            <b-card-text class="text"
              ><strong>{{state.invoiceDetails.invoiceToName}}</strong></b-card-text
            >
            <b-card-text class="text">{{
              state.selected
                ? state.selected.adddress
                : "San Diego County, CA 91905, USA"
            }}</b-card-text>
            <b-card-text class="text"
              >+1 (123) 456 7891, +44 (876) 543 2198</b-card-text
            >
            <b-card-text class="text"
              >+1 (123) 456 7891, +44 (876) 543 2198</b-card-text
            >
            <b-card-text class="text"
              >+1 (123) 456 7891, +44 (876) 543 2198</b-card-text
            >
          </div>
        </div>
        <div>
          <row-element-vue
            :customClass="'mb-2'"
            fieldText="Payment Details:"
            isFieldValueStrong
          />
          <row-element-vue
            :customClass="customClass"
            fieldText="Total Due:"
            :fieldValue="state.invoiceDetails.invoiceSubTotal+'$'"
            isTextStrong
          />
          <row-element-vue
            :customClass="customClass"
            fieldText="Bank name:"
            fieldValue="Bank of America"
          />

          <row-element-vue
            :customClass="customClass"
            fieldText="Country:"
            fieldValue="Saudi Arab"
          />
          <row-element-vue
            :customClass="customClass"
            fieldText="IBAN:"
            fieldValue="2rf2r3f334r3r34tgt4y"
          />
          <row-element-vue
            :customClass="customClass"
            fieldText="SWIFT code:"
            fieldValue="BR1092"
          />
        </div>
        <!-- </b-col> -->
        <!-- <b-col cols="4">
       
    </b-col> -->
      </b-row>
      <div v-if="$route.fullPath.includes('edit')">
        <new-item />
      </div>
      <b-row v-else>
        <b-table responsive :fields="invoiceFields" striped :items="JSON.parse(state.invoiceDetails.invoiceItems)">
        </b-table>
      </b-row>
      <b-row class="p-3 d-flex justify-content-between">
        <b-row v-if="$route.fullPath.includes('edit')" class="align-items-center">
          <label style="font-size: 16px; margin-right: 10px" for="inputName"
            >SalePerson:</label
          >
          <span> <b-form-input id="inputName" /></span>
        </b-row>
        <b-card-text v-else>
          <span class="fieldHeading">Salesperson: </span>
          <span>Alfie</span>
        </b-card-text>
        <div>
          <row-element-vue
            :customClass="customClass"
            fieldText="SubTotal:"
            :fieldValue="state.invoiceDetails.invoiceSubTotal+'$'"
            isTextStrong
          />
          <row-element-vue
            :customClass="customClass"
            fieldText="Discount:"
            fieldValue="28$"
          />
          <row-element-vue
            :customClass="customClass"
            fieldText="Tax:"
            fieldValue="21%"
          />
          <!-- <div class="border" style="margin-top: 1px;margin-bottom: 1px;"/> -->
          <row-element-vue
            :customClass="customClass"
            fieldText="Total:"
            :fieldValue="state.invoiceDetails.invoiceSubTotal+'$'"
          />
        </div>
      </b-row>

      <b-row>
        <div class="border" style="margin-bottom: 1rem" />
        <span class="pl-3 pr-3">
          <strong>Note:</strong> It was a pleasure working with you and your
          team. We hope you will keep us in mind for future freelance projects.
          Thank You!
        </span>
      </b-row>
    </b-card>
  </b-col>
    <b-row style="flex:1;margin-left: 0px;" >
      <b-col >
      <b-card  >
        <b-button
        block
        :disabled = "state.invoiceDetails &&  state.invoiceDetails.invoiceUrl ? false : true"
        :variant="state.invoiceDetails &&  state.invoiceDetails.invoiceUrl ?  'primary' : ''"
        @click="() => exportToPDF('print')"
      >
        Download
      </b-button>
      <!-- <b-button
        block
        variant="primary"
      >
        Edit
      </b-button> -->
    
    </b-card>
    </b-col>
  </b-row>
  </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BTable,
  BRow,
  BCard,
  BCardText,
  BCol,
  BFormDatepicker,
  BFormInput,
} from "bootstrap-vue";
import useInvoice from "./useInvoice";
import moment from "moment";
import RowElementVue from "./components/RowElement.vue";
import CustomerSelectVue from "./components/CustomerSelect.vue";
import NewItem from "./components/NewItem.vue";
import Loader from '../e-commerce/components/Loader.vue';

export default {
  components: {
    BButton,
    BTable,
    BFormDatepicker,
    Loader,
    CustomerSelectVue,
    NewItem,
    BCardText,
    BFormInput,
    RowElementVue,
    BRow,
    BCard,
    BCol,
  },
  data() {
    return {
      customClass: "cardText",
    };
  },
  updated() {
    // console.log('selected',state.selected);
  },
  methods: {},
  mounted(){
    console.log(this.$route);
    this.getInvoiceDetails(this.$route.params.id);
  },
  updated(){
  
  },
  setup() {
    console.log('updated',state);

    const {
      invoiceFields,
      items,
      exportToPDF,
      getInvoiceDetails,
      state,
      handleCustomerSelection,
    } = useInvoice();
    console.log("state", state);
    // const {selected} = state
    return {
      invoiceFields,
      items,
      getInvoiceDetails,
      handleCustomerSelection,
      state,
      // selected,
      exportToPDF,
    };
  },
};
</script>
<style>
.cardText {
  width: 8rem;
}
.fieldHeading {
  font-weight: 500;
}
.text {
  font-size: 16px;
}
.logoText {
  font-size: 32px;
  font-weight: 600;
  /* margin-left: 1rem; */
  color: #4a8cff;
}
.border {
  margin-top: 1rem;
  margin-bottom: 3rem;
  height: 1px;
  width: 100%;
  background-color: rgb(167, 167, 167);
}
</style>